import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import WineDetail from "./components/WineDetail";
import Home from "./container/Home";
import { Helmet } from "react-helmet";
import SanityUrls from "./SanityUrls";
import WineRoutes from "./container/WineRoutes";

function App() {
  // useEffect(() => {
  //   const user = fetchUser();
  //   // if (!user) navigate("login");
  // }, []);

  return (
    <>
      <Helmet>
        <title>
          Winery Seeker | Home | Find a winery near you | Winery ratings and
          reviews
        </title>
        <meta
          name="description"
          content="winery seeker, global winery database, winery vacations and trips, child friendly wineries "
          data-react-helmet="true"
        />
      </Helmet>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Home />} />
        <Route path="/winery/:slug" element={<WineDetail />} />
        <Route path="/sanityUrls" element={<SanityUrls />} />
        <Route path="/WineRoutes" element={<WineRoutes />} />
      </Routes>
    </>
  );
}

export default App;
