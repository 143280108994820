import React, { useState, useEffect } from "react";
import MapWithPins from "../components/MapWithPins";

const WinesMap = ({ wines }) => {
  // calculate center of all locations

  // console.log("Wines ", wines);

  const locations = wines?.map((d) => {
    let address =
      d.address.street + ", " + d.address.state + ", " + d.address.country;
    let lat = d.address.latitude;
    let lng = d.address.longitude;
    let title = d.title;
    let wineId = d._id;
    let hours = d.hours;
    let comments = d.comments;
    let slug = d.slug.current;
    return { address, lat, lng, title, wineId, hours, comments, slug };
  });

  // console.log("Locs: ", locations);

  const [coordinates, setCoordinates] = useState({
    lat: 38.060001,
    lng: -122.106667,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coordinates: { latitude, longitude } }) => {
        setCoordinates({ lat: latitude, lng: longitude });
      }
    );
  }, []);


  return (
    <div className="w-full h-full">
      <div className="mt-4">
        <MapWithPins
          locations={locations}
          zoomLevel={12}
          coordinates={coordinates}
        />
      </div>
    </div>
  );
};

export default WinesMap;
