import React, { useState, useRef, useEffect } from "react";
import { HiMenu } from "react-icons/hi";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link, Route, Routes } from "react-router-dom";

import { SidebarNew, UserProfile } from "../components";
import { client } from "../client";
import logo from "../assets/logo.png";
import Wines from "./Wines";
import ArticleGrid from "../components/ArticleGrid";
import Articles from "../components/Articles";
import Footer from "../components/Footer";

import WineRoutes from "./WineRoutes";

import { userQuery } from "../utils/data";
import jwt_decode from "jwt-decode";
import { fetchUser } from "../utils/fetchUser";
import Privacy from "./Privacy";
import Terms from "./Terms";

const Home = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [user, setUser] = useState(null);
  const scrollRef = useRef(null);

  const userInfo = fetchUser();

  // to fix issue with jtw_decode not handling nulls
  let googleId = null;

  if (userInfo !== null) {
    const { sub } = jwt_decode(userInfo);
    googleId = sub;
  }

  // get user from sanity
  useEffect(() => {
    const query = userQuery(googleId);
    client.fetch(query).then((data) => {
      setUser(data[0]);
    });
  }, [googleId]);

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex bg-gray-50 md:flex-row flex-col h-screen transition-height duration-75 ease-out">
      <div className="hidden md:flex h-screen flex-initial">
        <SidebarNew user={user && user} />
      </div>
      <div className="flex md:hidden flex-row">
        <div className="p-2 w-full flex flex-row justify-between items-center shadow-md">
          <HiMenu
            fontSize={40}
            className="cursor-pointer"
            onClick={() => setToggleSidebar(true)}
          />
          <Link to="/" className="flex flex-col items-center">
            <img
              src={logo}
              alt="logo"
              className="w-14 md:w-28"
              width="192"
              height="192"
            />
            <div className="text-xs font-extrabold text-gray-900 ml-2">
              Winery Seeker
            </div>
          </Link>
          {user && (
            <Link to={`user-profile/${user?._id}`}>
              <img
                src={user?.image}
                alt="user profile"
                className="w-28"
                referrerPolicy="no-referrer"
                height="28"
                width="28"
              />
            </Link>
          )}
        </div>
        {toggleSidebar && (
          <div className="fixed w-4/5 bg-white h-screen overflow-y-auto shadow-md z-50 animate-slide-in">
            <div className="absolute w-full flex justify-end items-center p-2">
              <AiFillCloseCircle
                fontSize={30}
                className="cursor-pointer"
                onClick={() => setToggleSidebar(false)}
              />
            </div>
            <SidebarNew user={user && user} closeToggle={setToggleSidebar} />
          </div>
        )}
      </div>
      <div className="pb-2 flex-1 h-screen overflow-y-scroll" ref={scrollRef}>
        <div className="mb-auto flex-grow">
          <Routes>
            <Route path="/user-profile/:userId" element={<UserProfile />} />
            <Route path="/*" element={<Wines user={user && user} />} />
            <Route path="/articles" element={<ArticleGrid />} />
            <Route path="/articles/:articleId" element={<Articles />} />
            <Route path="/routeplanner" element={<WineRoutes />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-of-service" element={<Terms />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
