import React from "react";

const Footer = () => {
  return (
    <footer className="mt-10 bg-sky-400 mx-auto max-w-7xl py-2 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8t-center">
      <nav
        className="mx-5 my-2 flex flex-wrap justify-center order-2"
        aria-label="Footer"
      >
        <div className="px-5">
          <a
            href="/terms-of-service"
            className="text-base text-white hover:text-gray-200"
          >
            Terms of Service
          </a>
        </div>

        <div className="px-5">
          <a
            href="/privacy-policy"
            className="text-base text-white hover:text-gray-200"
          >
            Privacy Policy
          </a>
        </div>
      </nav>
      <div className="text-base text-white md:order-1 md:mt-0">
        &copy; 2023 Wineryseeker.com
      </div>
    </footer>
  );
};

export default Footer;
