export const categories = [
  {
    name: "Argentina",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
  },
  {
    name: "Canada",
    image:
      "https://i.pinimg.com/236x/25/14/29/251429345940a47490cc3d47dfe0a8eb.jpg",
  },
  {
    name: "Chile",
    image:
      "https://i.pinimg.com/236x/03/48/b6/0348b65919fcbe1e4f559dc4feb0ee13.jpg",
  },
  {
    name: "France",
    image:
      "https://i.pinimg.com/750x/66/b1/29/66b1296d36598122e6a4c5452b5a7149.jpg",
  },
  {
    name: "Germany",
    image:
      "https://i.pinimg.com/236x/72/8c/b4/728cb43f48ca762a75da645c121e5c57.jpg",
  },
  {
    name: "Italy",
    image:
      "https://i.pinimg.com/236x/7d/ef/15/7def15ac734837346dac01fad598fc87.jpg",
  },
  {
    name: "New Zealand",
    image:
      "https://i.pinimg.com/236x/b9/82/d4/b982d49a1edd984c4faef745fd1f8479.jpg",
  },
  {
    name: "USA",
    image:
      "https://i.pinimg.com/736x/f4/e5/ba/f4e5ba22311039662dd253be33bf5f0e.jpg",
  },
];

export const wineDetailQuery = (wineId) => {
  const query = `*[_type == "winery" && slug.current == '${wineId}']{
      image{
        asset->{
          url
        }
      },
      _id,
      title, 
      about,
      category,
      destination,
      about,
      description,
      content,
      region,
      appellation,
      address {
        street,
        city,
        state,
        country,
        longitude,
        latitude
      },
      hours {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
      },
      contact {
        phone,
        email,
        website,
        reservationUrl
      },  
      services {
        weddings,
        tastings,
        tours,
        organic,
        food,
        accommodation,
        children,
        reservationsReq
      },  
      varietals,
      varietals_red,
      varietals_white,
      varietals_rose,
      save[]{
        postedBy->{
          _id,
          userName,
          image
        },
      },
      slug,
      tastingFee,
      comments[]{
        comment,
        rating,
        _key,
        postedBy->{
          _id,
          userName,
          image
        },
      }
    }`;
  return query;
};

export const wineDetailMoreWineQuery = (wine) => {
  const query = `*[_type == "winery" && appellation == '${wine.appellation}' && _id != '${wine._id}' ]{
      image{
        asset->{
          url
        }
      },
      _id,
      destination,
      title,
      about,
      description,
      content,
      region,
      appellation,
      address {
        street,
        city,
        state,
        country,
        longitude,
        latitude
      },  
        contact {
        phone,
        email,
        website,
        reservationUrl,
      },
      tastingFee,
      slug,
      save[]{
        _key,
        postedBy->{
          _id,
          userName,
          image
        },
      },
    }`;
  return query;
};

export const userQuery = (userId) => {
  const query = `*[_type == "user" && _id == '${userId}']`;
  return query;
};


export const searchFilterQuery = (searchTerm) => {
  const query = `*[_type == "winery" && (title match '${searchTerm}*' || region match '${searchTerm}*' || appellation match '${searchTerm}*' || address.country match '${searchTerm}*')] {
    image {
      asset -> {
        url
      }
    },
    _id,
    destination,
    title,
    region,
    appellation,
    address {
      street,
      city,
      state,
      country,
      longitude,
      latitude
    },
    contact {
      phone,
      email,
      website,
      reservationUrl,
    },  
    hours {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    },
    comments[]{
      rating,
      },
    save[] {
      _key,
      postedBy -> {
        _id,
        userName,
        image
      },
    },
    slug {
      current
    },
  }`;

  return query;
};

export const searchQuery = (
  searchTerm,
  food,
  accommodation,
  tour,
  fee,
  wedding
) => {
  const query = `*[_type == "winery" && tastingFee <= ${fee} && services.food in ${food} && services.weddings in ${wedding} && services.accommodation in ${accommodation} && services.tours in ${tour} && (title match '${searchTerm}*' || region match '${searchTerm}*' || appellation match '${searchTerm}*' || address.country match '${searchTerm}*')] {
    image {
      asset -> {
        url
      }
    },
    _id,
    destination,
    title,
    region,
    appellation,
    address {
      street,
      city,
      state,
      country,
      longitude,
      latitude
    },
    contact {
      phone,
      email,
      website,
      reservationUrl,
    },  
    hours {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    },
    comments[]{
      rating,
      },
    save[] {
      _key,
      postedBy -> {
        _id,
        userName,
        image
      },
    },
    slug {
      current
    },
  }`;

  return query;
};

export const feedQueryFilter = (food, accommodation, tour, fee, wedding) => {
  const query = `*[_type=="winery" && tastingFee <= ${fee} && services.food in ${food} && services.weddings in ${wedding} && services.accommodation in ${accommodation} && services.tours in ${tour}] | order(_createdAt desc) {
    image {
      asset -> {
        url
      }
    },
    _id,
    destination,
    title,
    region,
    appellation,
    address {
          street,
          city,
          state,
          country,
          longitude,
          latitude
    },
    contact {
      phone,
      email,
      website,
      reservationUrl,
    },
    hours {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    },
    comments[]{
      rating,
      },
    save[] {
      _key,
      postedBy -> {
        _id,
        userName,
        image
      },
    },
    slug {
      current
    },
  
  }`;
  return query;
};

export const feedQuery = `*[_type=="winery"] | order(_createdAt desc) {
  image {
    asset -> {
      url
    }
  },
  _id,
  destination,
  title,
  region,
  appellation,
  address {
        street,
        city,
        state,
        country,
        longitude,
        latitude
  },
  contact {
    phone,
    email,
    website,
    reservationUrl,
  },
  hours {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  },
  comments[]{
    rating,
    },
  save[] {
    _key,
    postedBy -> {
      _id,
      userName,
      image
    },
  },
  slug {
    current
  },

}`;

export const userSavedWinesQuery = (userId) => {
  const query = `*[_type == 'winery' && '${userId}' in save[].userId ] | order(_createdAt desc) {
    image{
      asset->{
        url
      }
    },
    _id,
    title,
    region,
    appellation,
    slug {
      current
    },  
    save[]{
      postedBy->{
        _id,
        userName,
        image
      },
    },
  }`;
  return query;
};

export const userRatedWinesQuery = (userId) => {
  const query = `*[_type == 'winery' && '${userId}' in comments[].userId ] | order(_createdAt desc) {
    image{
      asset->{
        url
      }
    },
    _id,
    title,
    region,
    appellation,
    slug {
      current
    },  
    comments[]{
      postedBy->{
        _id,
        userName,
        image
      },
      rating,
      comment
    },
  }`;
  return query;
};

export const articlesQuery = `*[_type == "article"] {
    _id,
    _createdAt,
    title,
    "slug": slug.current,
    "image": image.asset->url,
    url,
    alt,
    content
  }`;

export const articleQuery = (articleId) => {
  const query = `*[_type == 'article' && slug.current == '${articleId}' ] | order(_createdAt desc) {
      _id,
      _createdAt,
      title,
      "slug": slug.current,
      "image": image.asset->url,
      url,
      alt,
      content,
      metaKws,
      metaDescription,
      }`;
  return query;
};

export const slugsQuery = `*[defined(slug.current)]{
  _type == "winery" => {
    "slug": "wine-detail/" + slug.current
  },
  _type == "article" => {
    "slug": "articles/" + slug.current
  }
}`;

export const paginateQuery = (firstId, lastId) => {
  const query = `*[_type == "winery" ] | order(_id) [${firstId}...${lastId}] {
      image{
        asset->{
          url
        }
      },
      _id,
      destination,
      title,
      region,
      appellation,
      address {
        street,
        city,
        state,
        country,
        longitude,
        latitude
      },  
        contact {
        phone,
        email,
        website,
        reservationUrl,
      },
      slug,
    }`;
  return query;
};
