import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { client } from "../client";
import MasonryLayout from "./MasonryLayout";
import Spinner from "./Spinner";
import { feedQueryFilter, searchQuery } from "../utils/data";
import WinesMap from "../container/WinesMap";
import WineFilter from "./WineFilter";

const Feed = ({ toggleMapButton }) => {
  const [loading, setLoading] = useState(false);
  const { categoryId } = useParams();
  const [wines, setWines] = useState(null);
  const [filters, setFilters] = useState({
    accommodation: "[true,false]",
    food: "[true,false]",
    tour: "[true,false]",
    wedding: "[true,false]",
    boolAccommodation: false,
    boolFood: false,
    boolTour: false,
    boolWedding: false,
  });

  const [fee, setFee] = useState(1000);

  const clearFilters = () => {
    setFee(1000);
    setFilters({
      ...filters,
      accommodation: "[true,false]",
      food: "[true,false]",
      tour: "[true,false]",
      wedding: "[true,false]",
      boolAccommodation: false,
      boolFood: false,
      boolTour: false,
      boolWedding: false,
    });
  };

  const chooseFilter = (name, value, boolValue) => {
    setFilters({
      ...filters,
      [name]: value,
      [`bool${name.charAt(0).toUpperCase()}${name.slice(1)}`]: boolValue,
    });
  };

  const { accommodation, food, tour, wedding } = filters;

  const renderDescription = () => {
    switch (categoryId) {
      case "argentina":
        return "Argentine wines are produced primarily in the Mendoza region, located at the foot of the Andes Mountains, in Argentina. This region is known for its high-altitude vineyards, which benefit from abundant sunshine, low humidity, and vast temperature variations, resulting in grapes with intense flavors and aromas. Argentine wines are renowned for their bold, fruit-forward flavors, particularly in the Malbec varietal, which is the country's signature grape. Other notable varietals produced in Argentina include Cabernet Sauvignon, Syrah, and Torrontés, a fragrant white wine that is unique to the region.";
      case "mendoza":
        return "Wines from Mendoza, Argentina, are produced in one of the most renowned wine regions in South America, situated at the foothills of the Andes Mountains. This region is famous for its high-altitude vineyards, which produce grapes with concentrated flavors and aromas due to the region's warm, dry climate and ample sunlight. The wines produced in Mendoza are known for their robust, full-bodied character, particularly in the Malbec varietal, which is the region's most celebrated grape. Other notable varietals grown in Mendoza include Cabernet Sauvignon, Chardonnay, and Torrontés, all of which reflect the unique terroir of the region.";
      case "san juan":
        return "Wines from San Juan, Argentina, are produced in a wine region located north of Mendoza, bordered by the Andes Mountains to the west and the Pampas plains to the east. This region is known for its dry, desert-like climate and high altitude vineyards, which produce grapes with concentrated flavors and aromas. San Juan wines are celebrated for their full-bodied character, intense fruit flavors, and high acidity. The region is known for producing varietals such as Syrah, Bonarda, and Cabernet Sauvignon, as well as the indigenous Criolla grape, which is commonly used for making rosé wines. The wines from San Juan offer a unique expression of Argentina's diverse terroir and winemaking traditions.";
      case "canada":
        return "Canadian wines are produced in several wine regions across the country, including Ontario, British Columbia, and Quebec. Known for their cool-climate varietals such as Riesling, Chardonnay, and Pinot Noir, Canadian wines are renowned for their unique flavors, aromas, and characteristics that reflect the terroir of each region.";
      case "niagara":
        return "Niagara wines are produced in the Niagara Peninsula, located in southern Ontario, Canada. This wine region is known for its diverse range of cool-climate varietals, including Chardonnay, Riesling, Cabernet Franc, and Pinot Noir. Niagara wines are acclaimed for their high quality, elegant flavors, and distinct terroir, influenced by the region's proximity to Lake Ontario and the Niagara Escarpment.";
      case "prince edward county":
        return "Prince Edward County wines are produced in a unique wine region located on the northeastern shore of Lake Ontario in Ontario, Canada. This region is known for its cooler climate, with shorter growing seasons, which results in elegant, crisp, and mineral-driven wines. Prince Edward County is particularly renowned for its Chardonnay, Pinot Noir, and sparkling wines, which are characterized by their bright acidity, delicate fruit flavors, and subtle minerality.";
      case "chile":
        return "Chilean wines are produced in a long and narrow country located in the western part of South America, with a diverse range of microclimates that are influenced by the Pacific Ocean, the Andes Mountains, and the Atacama Desert. Chilean wines are known for their distinct and unique flavors, influenced by the country's diverse terroir and varietals. The country is particularly famous for its Carmenere grape, which is often considered the signature grape of Chile. Other notable varietals grown in Chile include Cabernet Sauvignon, Merlot, Chardonnay, and Sauvignon Blanc. Chilean wines are celebrated for their rich fruit flavors, balanced acidity, and exceptional value.";
      case "maipo":
        return "Wines from Maipo Valley are produced in one of Chile's most renowned wine regions, located south of Santiago. This region is known for its warm, Mediterranean climate, which is moderated by cool breezes from the Andes Mountains. Maipo Valley wines are celebrated for their rich, full-bodied character and exceptional quality, particularly in the Cabernet Sauvignon varietal, which is the region's most famous grape. Other notable varietals grown in Maipo Valley include Merlot, Carmenere, and Syrah, which all exhibit a distinct terroir influenced by the region's unique soil types, temperature variations, and elevation. The wines produced in Maipo Valley are considered some of Chile's finest and most prestigious, reflecting the country's proud winemaking heritage and commitment to quality.";
      case "aconcagua":
        return "Wines from Aconcagua, Chile, are produced in a wine region located north of Santiago, between the Andes Mountains and the Pacific Ocean. This region is known for its diverse terroir, with vineyards located at varying altitudes and influenced by cool ocean breezes, which create a unique climate for grape cultivation. Aconcagua wines are celebrated for their depth and complexity, particularly in the Syrah and Cabernet Sauvignon varietals, which are the region's most famous grapes. Other notable varietals grown in Aconcagua include Carmenere, Petit Verdot, and Malbec, which exhibit a distinct terroir influenced by the region's rocky soils, cool nights, and hot days. The wines produced in Aconcagua offer a unique expression of Chile's diverse winemaking traditions and terroir.";
      case "usa":
        return "Wines from the United States are produced in a diverse range of regions, each with its unique terroir and winemaking traditions. Some of the most famous wine regions in the US include Napa Valley, Sonoma County, and the Central Coast of California, as well as regions in Oregon and Washington State. American wines are celebrated for their diversity and quality, with a wide range of varietals grown, including Cabernet Sauvignon, Pinot Noir, Chardonnay, and Sauvignon Blanc, among others. The wines produced in the US offer a unique expression of the country's diverse winemaking heritage, reflecting both Old World and New World influences. American winemakers are known for their innovative techniques and commitment to quality, producing some of the finest wines in the world.";
      case "oregon":
        return "Wines from Oregon are produced in a state located in the Pacific Northwest region of the United States. This region is known for its diverse terroir, which includes a range of microclimates and soil types that provide ideal conditions for grape cultivation. Oregon wines are celebrated for their elegance and finesse, particularly in the Pinot Noir varietal, which is the region's most famous grape. Other notable varietals grown in Oregon include Chardonnay, Pinot Gris, and Riesling, among others, which exhibit a distinct terroir influenced by the region's cool, wet climate and volcanic soils. The wines produced in Oregon offer a unique expression of American winemaking traditions, reflecting a commitment to sustainable agriculture and a focus on producing wines of exceptional quality and complexity.";
      case "napa":
        return "Wines from Napa Valley are produced in one of the world's most prestigious wine regions, located in Northern California. This region is known for its Mediterranean climate, fertile soils, and unique terroir, which provide ideal conditions for grape cultivation. Napa Valley wines are celebrated for their rich, full-bodied character and exceptional quality, particularly in the Cabernet Sauvignon varietal, which is the region's most famous grape. Other notable varietals grown in Napa Valley include Chardonnay, Pinot Noir, Merlot, and Sauvignon Blanc, among others, which exhibit a distinct terroir influenced by the region's diverse soil types, temperature variations, and elevation. The wines produced in Napa Valley offer a unique expression of California's winemaking traditions, reflecting a commitment to quality and innovation that has made this region one of the most renowned wine destinations in the world.";
      case "washington":
        return "Wines from Washington State are produced in a region located in the Pacific Northwest of the United States, east of the Cascade Mountains. This region is known for its arid climate, which provides ideal conditions for grape cultivation, as well as its diverse terroir, which includes a range of soil types and elevations. Washington wines are celebrated for their full-bodied character and exceptional quality, particularly in the Cabernet Sauvignon and Merlot varietals, which are the region's most famous grapes. Other notable varietals grown in Washington include Chardonnay, Syrah, and Riesling, among others, which exhibit a distinct terroir influenced by the region's warm days and cool nights, as well as its volcanic soils. The wines produced in Washington offer a unique expression of American winemaking traditions, reflecting a commitment to sustainability and a focus on producing wines of exceptional quality and complexity.";
      case "france":
        return "Wines from France are some of the most highly esteemed and iconic in the world, with a long and storied history of winemaking traditions. The country is divided into several wine regions, each with its unique terroir and winemaking practices. Some of the most famous wine regions in France include Bordeaux, Burgundy, Champagne, and the Rhône Valley, among others. French wines are celebrated for their elegance, complexity, and ability to age, with a wide range of varietals grown, including Cabernet Sauvignon, Merlot, Pinot Noir, Chardonnay, Sauvignon Blanc, and many more. French winemakers are known for their focus on terroir, using sustainable and traditional methods to produce wines that reflect the unique characteristics of their respective regions. The wines produced in France offer a unique expression of the country's rich winemaking heritage, reflecting centuries of experience, expertise, and innovation.";
      case "burgundy":
        return "Wines from Burgundy are some of the most highly sought-after and iconic in the world, known for their elegance, finesse, and complexity. Burgundy is a historic wine region in eastern France, known for its unique terroir, which includes a diverse range of soils, exposures, and microclimates. The region is primarily known for its production of Pinot Noir and Chardonnay, which are considered some of the finest examples of these varietals in the world. The red wines of Burgundy are typically lighter in style and exhibit flavors of red fruit, earth, and spices, while the white wines are rich and complex, with flavors of stone fruit, citrus, and minerality. Burgundy winemakers are known for their strict adherence to traditional winemaking techniques, with a focus on low-intervention practices and minimal use of oak, allowing the wines to express the unique terroir of the region. The wines produced in Burgundy offer a unique expression of the region's rich winemaking heritage, reflecting centuries of expertise and dedication to quality.";
      case "bordeaux":
        return "Wines from Bordeaux are some of the most highly acclaimed and iconic in the world, known for their power, complexity, and age-worthiness. Bordeaux is a historic wine region located in southwestern France, known for its unique terroir, which includes a blend of gravelly soils, a temperate maritime climate, and a variety of microclimates. The region is primarily known for its production of red blends made from Cabernet Sauvignon, Merlot, and Cabernet Franc, which are often rich and full-bodied, with flavors of black fruit, tobacco, and earth. Bordeaux is also known for its production of white blends made from Sauvignon Blanc and Sémillon, which are typically crisp and refreshing, with notes of citrus and tropical fruit. Bordeaux winemakers are known for their focus on blending, using a variety of techniques to create wines that balance power, elegance, and complexity. The wines produced in Bordeaux offer a unique expression of the region's rich winemaking heritage, reflecting centuries of expertise and dedication to quality. Find a bourdeaux winery for a wine tasting now.";
      case "pomerol":
        return "Wines from Pomerol are some of the most sought-after and prestigious in the world, known for their rich, full-bodied flavor and complexity. Pomerol is a small wine appellation located on the right bank of the Bordeaux region in southwestern France, known for its unique terroir, which includes a mix of clay and gravel soils. The region is primarily known for its production of red blends made primarily from Merlot, which is often blended with Cabernet Franc and Cabernet Sauvignon. Pomerol wines are typically rich and full-bodied, with flavors of dark fruit, chocolate, and spices, and often exhibit a velvety texture and a long, lingering finish. Pomerol winemakers are known for their focus on traditional winemaking techniques, with a focus on low yields and meticulous attention to detail throughout the winemaking process. The wines produced in Pomerol offer a unique expression of the region's rich winemaking heritage, reflecting centuries of expertise and dedication to quality.";
      case "greece":
        return "Wines from Greece are becoming increasingly popular and gaining recognition on the world stage, known for their unique flavor profiles and distinctive regional styles. Greece has a long and rich history of winemaking, dating back to ancient times, and the country is home to a diverse range of grape varieties and wine regions. The most well-known Greek grape varieties include Assyrtiko, Xinomavro, and Agiorgitiko, among others, each with their own unique flavor profile and character. Greek wines are often described as being crisp, mineral-driven, and aromatic, with flavors of citrus, stone fruit, and herbs. The country's main wine regions include the Aegean Islands, the Peloponnese, and Macedonia, each with their own unique terroir and winemaking traditions. Greek winemakers are known for their focus on organic and biodynamic practices, using minimal intervention in the winemaking process to allow the wines to express their unique character and terroir. The wines produced in Greece offer a unique expression of the country's rich winemaking heritage, reflecting centuries of expertise and a deep connection to the land.";
      case "italy":
        return "Italy is synonymous with wine, boasting a heritage that stretches back thousands of years. From the rolling hills of Tuscany to the volcanic soils of Sicily, the country's diverse landscapes give birth to some of the world’s most revered and distinctive wines. Italian wines offer a sublime mix of tradition, terroir, and taste, captivating wine enthusiasts across the globe.\nItaly is home to 20 wine-growing regions, each producing wines with distinct flavors and personalities. Whether you’re exploring the elegance of Piedmont, the rustic charm of Tuscany, or the coastal breezes of Campania, Italian wines offer a diversity of experiences. The country’s geographical diversity ensures that every bottle carries a unique story rooted in the land it comes from."
      case "abruzzo":
        return "Tucked between the Apennine Mountains and the Adriatic Sea, Abruzzo is a wine region that offers some of Italy's most charming yet underrated wines. With rolling hills, ancient vineyards, and a perfect Mediterranean climate, Abruzzo is a hidden gem that delivers wines brimming with character, authenticity, and a sense of place. Here, the connection between the land and the bottle is strong, offering a delightful mix of tradition, innovation, and genuine passion."
      case "piedmont":
        return "Piedmont’s crown jewels are undoubtedly Barolo and Barbaresco, both made from the noble Nebbiolo grape. Often referred to as the 'King of Wines and the Wine of Kings' Barolo is renowned for its power, complexity, and longevity. This full-bodied red is known for its deep garnet color and a complex bouquet of rose, tar, cherry, and truffle. With pronounced tannins and high acidity, Barolo has incredible aging potential, with some bottles developing beautifully over decades. <br /><br />Barbaresco, often seen as Barolo’s more feminine counterpart, is also crafted from Nebbiolo but comes from a slightly different terroir. Barbaresco is typically more approachable at a younger age, offering elegance and finesse with notes of red fruits, violets, and spices, yet it still possesses the structure and depth that Nebbiolo wines are known for. <br /><br />Both wines are often described as having an almost ethereal quality—delicate yet powerful, austere yet aromatic. They pair beautifully with hearty dishes like braised meats, truffle risottos, and rich cheeses, making them the perfect complement to Piedmont’s rich culinary traditions."
      case "puglia":
        return "Located in the heel of Italy’s boot, Puglia is a region of incredible beauty, rich history, and a long-standing tradition of winemaking. Known for its sunny climate, fertile soils, and centuries-old vineyards, Puglia (or Apulia) is home to some of Italy's most expressive and full-bodied wines. The region produces a wide variety of wines, from powerful reds to crisp whites, offering a diversity that reflects both its ancient winemaking traditions and modern innovation. <br /><br />Primitivo is Puglia’s most famous grape and the star of the region’s wine scene. This robust and powerful red wine is known for its deep color, rich fruit flavors, and bold tannins. Primitivo is genetically identical to Zinfandel, but in Puglia, the grape takes on a unique character thanks to the region’s warm Mediterranean climate and limestone-rich soils."
      case "tuscany":
        return "At the core of Tuscany’s winemaking heritage is Chianti Classico, one of Italy’s most famous and beloved wines. Made primarily from the Sangiovese grape, Chianti Classico is known for its vibrant acidity, firm tannins, and flavors of ripe cherries, red berries, herbs, and earthy notes. With its signature black rooster logo, this wine is a symbol of tradition and quality. <br /><br /> Chianti Classico wines can range from light and fruity to more complex and age-worthy expressions, depending on the producer and the specific vineyard location. This versatility makes Chianti Classico an excellent companion for a wide variety of dishes, from hearty Tuscan pasta like pappardelle al cinghiale (wild boar ragu) to pizza, grilled meats, and even aged cheeses. It is a wine that embodies the rustic charm and warmth of the Tuscan countryside."
      case "veneto":
        return "Veneto wines are pretty"
      default:
        return "Winery Seeker helps you find and discover wineries around the world. Signup to review, plan routes and save your favorite wineries. Find a winery near you today. Find the best winery and tastings near you today!";
    }
  };

  useEffect(() => {
    setLoading(true);

    if (categoryId) {
      const query = searchQuery(
        categoryId,
        food,
        accommodation,
        tour,
        fee,
        wedding
      );
      client.fetch(query).then((data) => {
        setWines(data);
        setLoading(false);
      });
    } else {
      const feedQuery = feedQueryFilter(
        food,
        accommodation,
        tour,
        fee,
        wedding
      );
      client.fetch(feedQuery).then((data) => {
        setWines(data);
        setLoading(false);
      });
    } // eslint-disable-next-line
  }, [categoryId, filters, fee]);

  if (loading) return <Spinner message="Searching for wineries...!" />;

  if (!wines?.length)
    return (
      <h2>
        No results available. Click{" "}
        <Link onClick={clearFilters} className="text-lg text-green-600">
          here
        </Link>{" "}
        to refresh
      </h2>
    );

  if (toggleMapButton) return <div>{wines && <WinesMap wines={wines} />}</div>;
  console.log("Category ID", categoryId)

  return (
    <div>
      <div className="mt-4 bm-4 w-full rounded overflow-hidden shadow-lg bg-slate-50 pl-2 pr-2 pt-4 pb-4">
        {renderDescription()}
      </div>
      <WineFilter
        filters={filters}
        chooseFilter={chooseFilter}
        fee={fee}
        setFee={setFee}
      />
      {wines && <MasonryLayout wines={wines} />}
    </div>
  );
};

export default Feed;
