import React, { useState, useEffect } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import { googleLogout, GoogleOAuthProvider } from "@react-oauth/google";

import {
  userRatedWinesQuery,
  userQuery,
  userSavedWinesQuery,
} from "../utils/data";
import { client } from "../client";
import MasonryLayoutSearch from "./MasonryLayoutSearch";
import Spinner from "./Spinner";

import jwt_decode from "jwt-decode";
import { fetchUser } from "../utils/fetchUser";

const randomImage = "https://source.unsplash.com/1600x900/?wine,winery,nature";

const activeBtnStyles =
  "bg-red-500 text-white font-bold p-2 rounded-full w-20 outline-none";
const notActiveBtnStyles =
  "bg-primary mr-4 text-black font-bold p-2 rounded-full w-20 outline-none";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [wines, setWines] = useState(null);
  const [text, setText] = useState("Created");
  const [activeBtn, setActiveBtn] = useState("created");
  const navigate = useNavigate();
  const { userId } = useParams();
  // not using - let anyone view profile
  // const User =
  //   localStorage.getItem("user") !== "undefined"
  //     ? JSON.parse(localStorage.getItem("user"))
  //     : localStorage.clear();

  useEffect(() => {
    const query = userQuery(userId);
    client.fetch(query).then((data) => setUser(data[0]));
  }, [userId]);

  useEffect(() => {
    if (text === "Rated") {
      const ratedWinesQuery = userRatedWinesQuery(userId);
      client.fetch(ratedWinesQuery).then((data) => {
        setWines(data);
      });
    } else {
      const savedWinesQuery = userSavedWinesQuery(userId);
      client.fetch(savedWinesQuery).then((data) => {
        setWines(data);
      });
    }
  }, [text, userId]);

  const logout = () => {
    localStorage.clear();
    googleLogout();
    navigate("/login");
  };

  const userInfo = fetchUser();

  // to fix issue with jtw_decode not handling nulls
  let googleId = null;

  if (userInfo !== null) {
    const { sub } = jwt_decode(userInfo);
    googleId = sub;
  }

  if (!user) {
    return <Spinner message="Loading profile ..." />;
  }
  return (
    <div className="relative pb-2 h-full justify-center items-center">
      <div className="flex flex-col pb-5">
        <div className="relative flex flex-col mb-7">
          <div className="flex flex-col justify-center items-center">
            <img
              src={randomImage}
              className="w-full h-370 2xl:h-510 shadow-lg object-cover"
              alt="banner"
            />
            <img
              src={user.image}
              className="rounded-full w-20 h-20 -mt-10 shadow-xl object-cover"
              alt="user profile"
            />
            <h1 className="fond-bold text-3xl text-center mt-3">
              {user.userName}
            </h1>
            <div className="absolute top-0 z-1 right-0 p-2">
              {userId === googleId && (
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}
                >
                  <button
                    type="button"
                    className="bg-white p-2 rounded-full cursor-pointer outline-none shadow-md"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <AiOutlineLogout color="red" fontSize={21} />
                  </button>
                </GoogleOAuthProvider>
              )}
            </div>
          </div>
          <div className="text-center mb-7">
            <button
              type="button"
              onClick={(e) => {
                setText(e.target.textContent);
                setActiveBtn("rated");
              }}
              className={`${
                activeBtn === "rated" ? activeBtnStyles : notActiveBtnStyles
              }`}
            >
              Rated
            </button>
            <button
              type="button"
              onClick={(e) => {
                setText(e.target.textContent);
                setActiveBtn("saved");
              }}
              className={`${
                activeBtn === "saved" ? activeBtnStyles : notActiveBtnStyles
              }`}
            >
              Saved
            </button>
          </div>

          {wines?.length ? (
            <div className="px-2">
              <MasonryLayoutSearch wines={wines} />
            </div>
          ) : (
            <div className="flex justify-center font-bold items-center w-full text-xl mt-2">
              Nothing found.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
