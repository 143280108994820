import React from "react";
import { Rate } from "antd";
import { useStateContext } from "../context/StateContext";

const PopupRoutes = ({ showModal, setShowModal }) => {
  const { addRoute, cartItems, removeRoute } = useStateContext();

  // Get hours for today
  const d = new Date();
  const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  let weekday = weekdays[d.getDay()];

  // Sum rating

  let ratingSum = 0;
  let rating = 0;
  if (showModal.comments) {
    for (var x in showModal.comments) {
      ratingSum += showModal.comments[x].rating;
    }
    rating = ratingSum / showModal.comments.length;
  }

  const checkWineryInCart = cartItems.find(
    (item) => item._id === showModal._id
  );

  // const filteredDay = locations.hours.filter((x) => "name" in weekday);
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-3xl font-semibold">{showModal.title}</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="bg-transparent text-red opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
            <div className="font-bold text-xs">
              <Rate
                defaultValue={rating}
                allowHalf
                allowClear={false}
                disabled
              />
            </div>
            <p className="my-4 text-slate-500 text-sm leading-relaxed">
              {showModal.address}
            </p>
            <p className="my-4 text-slate-500 text-sm leading-relaxed">
              Hours today: {showModal?.hours[weekday]}
            </p>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
            {checkWineryInCart ? (
              <button
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => removeRoute(showModal)}
              >
                Remove Route
              </button>
            ) : (
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => addRoute(showModal)}
              >
                Add Route
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupRoutes;
