import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { client, urlFor } from "../client";
import { fetchUser } from "../utils/fetchUser";
import jwt_decode from "jwt-decode";

const Wine = ({
  wine: { appellation, image, region, save, title, _id, contact, slug },
}) => {
  const [postHovered, setPostHovered] = useState(false);

  const navigate = useNavigate();

  const user = fetchUser();

  let googleId = null;

  if (user !== null) {
    const { sub } = jwt_decode(user);
    googleId = sub;
  }

  const alreadySaved = !!save?.filter(
    (item) => item?.postedBy?._id === googleId
  )?.length;

  const saveWine = (id) => {
    if (!alreadySaved) {
      client
        .patch(id)
        .setIfMissing({ save: [] })
        .insert("after", "save[-1]", [
          {
            _key: uuidv4(),
            userId: googleId,
            postedBy: {
              _type: "postedBy",
              _ref: googleId,
            },
          },
        ])
        .commit()
        .then(() => {
          window.location.reload();
        });
    }
  };

  return (
    <div className="m-2">
      <div
        onMouseEnter={() => setPostHovered(true)}
        onMouseLeave={() => setPostHovered(false)}
        onClick={() => navigate(`/wine-detail/${slug.current}`)}
        className="relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
      >
        {image ? (
          <div className="w-full aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg w-full"
              alt={title}
              src={urlFor(image).width(250).auto("format").url()}
              width="250"
              height="400"
            />
          </div>
        ) : (
          <img
            className="rounded-lg w-full"
            alt="user post"
            src="https://placehold.co/250x250"
            width="250"
            height="250"
          />
        )}
        {postHovered && (
          <div
            className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-50"
            style={{ height: "100%" }}
          >
            <div className="flex items-center justify-between">
              <div className="flex gap-2">&nbsp;</div>
              {alreadySaved ? (
                <button
                  type="button"
                  className="bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outlined-none"
                >
                  {save?.length} Saved
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outlined-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    saveWine(_id);
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <p>{title}</p>
      <p>
        {region} | {appellation}
      </p>
    </div>
  );
};

export default Wine;
