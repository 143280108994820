import React from "react";
import { NavLink, Link } from "react-router-dom";
import { RiHomeFill } from "react-icons/ri";
import { BiLogInCircle, BiLogOutCircle, BiUser, BiNews } from "react-icons/bi";
import { FaRoute } from "react-icons/fa";
import logo from "../assets/logo.png";
import { SidebarData } from "../utils/SidebarData";
import SubMenu from "./SubMenu";

const SidebarNew = ({ user, closeToggle }) => {
  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  const logout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };

  const isNotActiveStyle =
    "mb-1 flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize";
  const isActiveStyle =
    "mb-1 flex items-center px-5 gap-3 font-extrabold virder-r-2 border-black transition-all duration-200 ease-in-out capitalize";

  return (
    <div className="flex flex-col justify-between bg-white h-full overflow-y-scroll min-w-210 hide-scrollbar">
      <div className="flex flex-col">
        <Link
          to="/"
          className="flex flex-col px-5 gap-2 my-6 pt-1 w-190 items-center"
          onClick={handleCloseSidebar}
        >
          <img
            src={logo}
            alt="logo"
            className="w-full"
            height="192"
            width="192"
          />
          <div className="bg-gradient-to-r from-orange-400 via-red-400 to-purple-600 bg-clip-text text-transparent text-sm drop-shadow font-extrabold">
            Winery Seeker
          </div>
        </Link>
        <div className="flex flex-col gap-5">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <RiHomeFill />
            Home
          </NavLink>
          {!user && (
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive ? isActiveStyle : isNotActiveStyle
              }
              onClick={handleCloseSidebar}
            >
              <BiLogInCircle />
              Login
            </NavLink>
          )}
          {user && (
            <div>
              <NavLink to="/" className={isNotActiveStyle} onClick={logout}>
                <BiLogOutCircle />
                Logout
              </NavLink>
              <NavLink
                to={`user-profile/${user?._id}`}
                className={isNotActiveStyle}
              >
                <BiUser />
                Profile
              </NavLink>
            </div>
          )}

          <NavLink
            to="/articles"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <BiNews />
            Articles
          </NavLink>

          <NavLink
            to="/routeplanner"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <FaRoute />
            Route Planner
          </NavLink>

          <h3 className="mt-2 px-5 text-base 2xl:text-xl font-bold">
            Find Wineries
          </h3>

          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </div>
      </div>
      {user && (
        <Link
          to={`user-profile/${user?._id}`}
          className="flex my-5 mb-3 gap-2 p-2 items-center bg-white rounded-lg shadow-lg mx-3"
          onClick={handleCloseSidebar}
        >
          <img
            src={user.image}
            className="w-10 h-10 rounded-full"
            alt="user-profile"
            height="25"
            width="25"
          />
          <p>{user.userName}</p>
        </Link>
      )}
    </div>
  );
};

export default SidebarNew;
