import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { articleQuery } from "../utils/data";
import { PortableText } from "@portabletext/react";
import Spinner from "./Spinner";
import { client, urlFor } from "../client";
import { Helmet } from "react-helmet";

const Articles = () => {
  const [articleDetail, setArticleDetail] = useState(null);

  const { articleId } = useParams();

  const fetchArticleDetails = useCallback(() => {
    let query = articleQuery(articleId);

    if (query) {
      client.fetch(query).then((data) => {
        setArticleDetail(data[0]);
      });
    }
  }, [articleId]);

  const ptComps = {
    block: {
      h1: ({ children }) => (
        <h1 className="Text-4xl pt-2 font-extrabold">{children}</h1>
      ),

      h2: ({ children }) => (
        <h2 className="text-indigo-900 text-3xl pt-2 font-extrabold">
          {children}
        </h2>
      ),
      h3: ({ children }) => (
        <h3 className="text-indigo-900 text-3xl pt-2 font-extrabold">
          {children}
        </h3>
      ),
      normal: ({ children }) => <p className="p-2">{children}</p>,
    },
    list: {
      bullet: ({ children }) => (
        <div className="mx-auto pl-12 pr-2">
          <ul className="mt-xl list-disc">{children}</ul>
        </div>
      ),
    },
  };

  // console.log("Arcticle: ", articleId, " ", articleDetail);
  useEffect(() => {
    fetchArticleDetails();
  }, [articleId, fetchArticleDetails]);

  if (!articleDetail) return <Spinner message="Loading Article ..." />;

  return (
    <>
      <Helmet>
        <title>Winery Seeker | Articles | {articleDetail.title} |</title>
        <meta
          name="title"
          content={("Winery Seeker Aricles - ", articleDetail.metaKws)}
        />
        <meta name="description" content={articleDetail.metaDescription} />
        <link
          rel="preload"
          as="image"
          href={urlFor(articleDetail.image).auto("format").url()}
        />
      </Helmet>

      <div>
        <h1 className="text-center mt-4 mb-4 bg-gradient-to-r from-orange-400 via-red-400 to-purple-600 bg-clip-text text-transparent text-5xl drop-shadow font-extrabold">
          {articleDetail.title}
        </h1>
        <div className="pl-2 pr-2 flex justify-center items-center md:items-start flex-initial">
          <img
            src={
              articleDetail?.image &&
              urlFor(articleDetail.image).auto("format").url()
            }
            className="rounded-t-3xl rounded-b-lg"
            alt={articleDetail.title}
            height="500"
            width="1200"
          />
        </div>

        <div className="text-lg text-gray-700 mt-10 p-10">
          <PortableText value={articleDetail.content} components={ptComps} />
        </div>
      </div>
    </>
  );
};

export default Articles;
