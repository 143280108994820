import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import React from "react";

const LocationPin = ({ text, setShowModal, location }) => {
  return (
    <>
      <div className="flex items-center w-[180px] text-[#00009E]">
        {/* <Icon icon={locationIcon} className="text-6xl" onClick={(key) => console.log(key, " haha ", text)} /> */}
        <Icon
          icon={locationIcon}
          className="text-6xl"
          onClick={() => setShowModal(location)}
        />
        <p className="text-sm">{text}</p>
      </div>
    </>
  );
};
export default LocationPin;
