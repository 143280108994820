import React, { createContext, useContext, useState } from "react";
import { toast } from "react-hot-toast";

const Context = createContext();

export const StateContext = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [showRoutes, setShowRoutes] = useState(false);

  const addRoute = (winery) => {
    const checkWineryInCart = cartItems.find((item) => item._id === winery._id);
    if (checkWineryInCart) {
    } else {
      setCartItems([...cartItems, { ...winery }]);
    }
    toast.success(`${winery.title} added to planner`);
  };

  const removeRoute = (winery) => {
    const newCartItems = cartItems.filter((item) => item._id !== winery._id);
    setCartItems(newCartItems);
  };

  return (
    <Context.Provider
      value={{
        cartItems,
        setCartItems,
        addRoute,
        removeRoute,
        showRoutes,
        setShowRoutes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
