import { Dropdown } from "flowbite-react";

const WineFilter = ({ filters, chooseFilter, fee, setFee }) => {
  // const [food, setFood] = useState(false);
  // const [accommodation, setAccommodation] = useState(false);
  // const [tour, setTour] = useState(false);

  const { boolAccommodation, boolFood, boolTour, boolWedding } = filters;

  const handleChange = (name, value) => {
    const newValue = value ? "[true]" : "[true,false]";
    const boolValue = value ? true : false;
    chooseFilter(name, newValue, boolValue);
  };

  const handlePriceChange = (event, newValue) => {
    setFee(event);
  };

  const filterItems = [
    {
      name: "accommodation",
      label: "Accommodation",
      checked: boolAccommodation,
    },
    {
      name: "food",
      label: "Food",
      checked: boolFood,
    },
    {
      name: "tour",
      label: "Tours",
      checked: boolTour,
    },
    {
      name: "wedding",
      label: "Weddings",
      checked: boolWedding,
    },
  ];

  const filterPrice = [
    {
      name: "free",
      label: "Free",
      value: 1,
    },
    {
      name: "underTwenty",
      label: "<= $20",
      value: 20,
    },
    {
      name: "underFifty",
      label: "<= $50",
      value: 50,
    },
    {
      name: "underHundred",
      label: "<= $100",
      value: 100,
    },
    {
      name: "underTwoHundred",
      label: "<= $200",
      value: 200,
    },
    {
      name: "any",
      label: "All",
      value: 1000,
    },
  ];

  return (
    <div className="sticky top-0 bg-indigo-500 text-indigo-50 z-10">
      <header>
        <ul className="list-none flex justify-center gap-4">
          <li className="p-2">
            <Dropdown label="Tasting Fee" size="sm" color="bg-indigo-500">
              {filterPrice.map((price) => (
                <Dropdown.Item key={price.name}>
                  <input
                    id={price.name}
                    type="radio"
                    value={price.value}
                    name="default-radio"
                    className="w-4 h-4 bg-gray-100 border-gray-300 text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    onChange={(e) =>
                      handlePriceChange(price.value, e.target.checked)
                    }
                    checked={price.value === fee}
                  />
                  <label
                    htmlFor={price.name}
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                  >
                    {price.label}
                  </label>
                </Dropdown.Item>
              ))}
            </Dropdown>
          </li>
          <li className="p-2">
            <Dropdown label="Options" size="sm" color="bg-indigo-500">
              {filterItems.map((filter) => (
                <Dropdown.Item key={filter.name}>
                  <input
                    id={filter.name}
                    type="checkbox"
                    value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    onChange={(e) =>
                      handleChange(filter.name, e.target.checked)
                    }
                    defaultChecked={filter.checked}
                  />
                  <label
                    htmlFor={filter.name}
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                  >
                    {filter.label}
                  </label>
                </Dropdown.Item>
              ))}
            </Dropdown>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default WineFilter;
