import React, { useState, useEffect } from "react";
import MapWithRoutes from "../components/MapWithRoutes";
import { feedQuery } from "../utils/data";
import { client } from "../client";

const WineRoutes = () => {
  const [wines, setWines] = useState(null);

  useEffect(() => {
    const query = feedQuery;
    client.fetch(query).then((data) => {
      setWines(data);
    });
    // eslint-disable-next-line
  }, []);

  return <MapWithRoutes wines={wines} />;
};

export default WineRoutes;
