import React from "react";

const Privacy = () => {
  return (
    <>
      <iframe
        className="w-full h-full"
        title="privacy policy"
        srcDoc={`
        <div id="__enzuzo-root"></div><script id="__enzuzo-root-script" src="https://app.enzuzo.com/__enzuzo-privacy-app.js?mode=privacy&apiHost=https://app.enzuzo.com&qt=1690305386240&referral=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzU4MiwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1XNEJFdGRyOCIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2OTAzMDUzMzd9.e4sS46144auou6r6Rpx-vu3bAn3z4uVvlQtjKAYmHiA"></script>`}
      />
    </>
  );
};

export default Privacy;
