import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";
import Popup from "./Popup";

const MapWithPins = ({ locations, zoomLevel }) => {
  // const [selectedCenter, setSelectedCenter] = useState("Text")
  const [showModal, setShowModal] = useState(null);

  return (
    // const bounds = new window.google.maps.LatLngBounds();
    // locations.forEach((location) => {
    //   bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
    // });
    // const center = bounds.getCenter();
    // const center = this.googleMap.current.getCenter();

    <div className="map">
      <div className="w-full h-[90vh]">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_TOKEN }}
          defaultCenter={locations[0]}
          defaultZoom={zoomLevel}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            minZoom: 4,
            maxZoom: 18,
          }}
        >
          {locations.map((location, index) => (
            <LocationPin
              lat={location.lat}
              lng={location.lng}
              text={location.title}
              key={index}
              setShowModal={setShowModal}
              location={location}
              // onClick={() => {
              //   setSelectedCenter(location)
              // }}
            />
          ))}
        </GoogleMapReact>
      </div>
      {showModal && <Popup showModal={showModal} setShowModal={setShowModal} />}
    </div>
  );
};

export default MapWithPins;
