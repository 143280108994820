import React, { useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import Spinner from "./Spinner";
import PopupRoutes from "./PopupRoutes";
import { useStateContext } from "../context/StateContext";
import WineRoutesList from "./WineRoutesList";
import { Helmet } from "react-helmet";

const MapWithRoutes = ({ wines }) => {
  const locations = wines?.map((d) => {
    let address =
      d.address.street + ", " + d.address.state + ", " + d.address.country;
    let lat = d.address.latitude;
    let lng = d.address.longitude;
    let title = d.title;
    let wineId = d._id;
    let hours = d.hours;
    let comments = d.comments;
    let slug = d.slug.current;
    let _id = d._id;
    let image = d.image;
    return {
      address,
      lat,
      lng,
      title,
      wineId,
      hours,
      comments,
      slug,
      _id,
      image,
    };
  });

  const libraries = ["places"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN,
    libraries: libraries,
  });

  const { cartItems, setCartItems, showRoutes, setShowRoutes } =
    useStateContext();
  // eslint-disable-next-line
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  // const [duration, setDuration] = useState("");
  // const [waypts, setWaypts] = useState([]);

  const center = { lat: 43.1447611, lng: -79.504109 };

  if (!isLoaded) {
    return <Spinner message="Searching for wineries...!" />;
  }

  const splitArray = (list) => {
    const { 0: first, [list.length - 1]: last, ...rest } = list;
    return [first, Object.values(rest), list.length > 1 ? last : undefined];
  };

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance("");
    // setDuration("");
    setCartItems([]);
  }

  async function calculateRoute() {
    if (cartItems.length < 2) {
      return;
    }

    const [first, middle, last] = splitArray(cartItems);
    const waypts = [];
    for (let i = 0; i < middle.length; i++) {
      // setWaypts(waypts => [...waypts, {
      //   location: {
      //     lat: middle[i].lat,
      //     lng: middle[i].lng,
      //   }
      // }])
      waypts.push({
        location: {
          lat: middle[i].lat,
          lng: middle[i].lng,
        },
        stopover: true,
      });
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: first,
      destination: last,
      waypoints: waypts,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
  }

  return (
    <>
      <Helmet>
        <title>
          Winery Seeker | Winery Route Planner | Interactive winery map
        </title>
        <meta
          name="title"
          content="winery route planner - plan your winery trip"
        />
        <meta
          name="description"
          content="Plan your winery vacation with our interactive winery route planner, plan your Napa Valley or Niagara Falls getaway"
        />
      </Helmet>

      <div>
        <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6">
          <div className="w-full block flex-grow flex items-center w-auto">
            <div className="text-sm flex-grow">
              <button
                onClick={calculateRoute}
                className="block mt-4 inline-block mt-0 text-teal-200 hover:text-white mr-4 font-bold"
              >
                Calculate Route
              </button>
              <button
                onClick={clearRoute}
                className="block mt-4 inline-block mt-0 text-teal-200 hover:text-white mr-4 font-bold"
              >
                Reset Route
              </button>
              <span className="text-slate-100 border-solid border-white outline-1">
                {distance}
              </span>
            </div>
            <div>
              <button
                className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 mt-0"
                onClick={() => setShowRoutes(!showRoutes)}
              >
                My Routes
              </button>
            </div>
          </div>
        </nav>
        {/* <button
        type="button"
        className="inline-block rounded bg-green-500 px-6 pb-2 pt-2.5 text-xs uppercase text-white "
        onClick={calculateRoute}
      >
        Calculate Route
      </button>
      <button
        type="button"
        className="inline-block rounded bg-red-500 px-6 pb-2 ml-4 pt-2.5 text-xs uppercase text-white "
        onClick={clearRoute}
      >
        Reset
      </button> */}
        <div className="map">
          <div className="w-full h-[90vh] pt-2">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={(map) => setMap(map)}
            >
              {locations &&
                locations.map((item) => (
                  <Marker
                    key={item._id}
                    icon={
                      "https://maps.google.com/mapfiles/kml/paddle/blu-blank.png"
                    }
                    position={item}
                    onClick={() => {
                      setSelectedCenter(item);
                    }}
                  />
                ))}
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
        </div>
        {selectedCenter && (
          <PopupRoutes
            showModal={selectedCenter}
            setShowModal={setSelectedCenter}
          />
        )}
        {showRoutes && <WineRoutesList />}
      </div>
    </>
  );
};

export default MapWithRoutes;
