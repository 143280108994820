import React from "react";
import { useStateContext } from "../context/StateContext";
import { AiOutlineLeft } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { urlFor } from "../client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const WineRoutesList = () => {
  const { setShowRoutes, cartItems, setCartItems, removeRoute } =
    useStateContext();

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(cartItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCartItems(items);
  }

  return (
    <div className="w-screen bg-black/50 top-0 right-0 fixed z-[100] transition duration-100 ease-in-out">
      <div className="h-screen w-[415px] md:w-[700px]  bg-white float-right py-10 px-2 relative">
        <button
          type="button"
          className="flex items-center text-lg font-medium cursor-pointer gap-2 ml-10 border-none bg-transparent"
          onClick={() => setShowRoutes(false)}
        >
          <AiOutlineLeft />
          <span className="ml-10">Your Routes</span>
        </button>
        {cartItems.length < 1 && (
          <div className="m-[40px] text-center">
            <h3 className="font-semibold text-xl">
              There are no routes added.
            </h3>
          </div>
        )}

        <div className="mt-15 max-h-[70vh] px-2 py-4 overflow-auto">
          {cartItems.length >= 1 && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="wineries">
                {(provided) => (
                  <ul
                    className="wineries pl-0 list-none"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {cartItems.map((item, index) => {
                      return (
                        <Draggable
                          key={item.wineId}
                          draggableId={item.wineId}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="flex p-2 gap-[10px] border-b-2 border-indigo-100"
                            >
                              <div className="characters-thumb">
                                <img
                                  src={
                                    item.image &&
                                    urlFor(item.image).auto("format").url()
                                  }
                                  alt={`${item.title} thumb`}
                                  className="w-[100px] h-[100px] rounded-2xl bg-[#EBEBEB]"
                                />
                              </div>
                              <div className="m-1">
                                <h5 className="font-medium text-lg">
                                  {item.title}
                                </h5>
                                <p className="mt-0">{item.address}</p>
                                <button
                                  type="button"
                                  className="text-2xl text-[#f02d34] cursor-pointer bg-transparent border-none mt-2"
                                  onClick={() => removeRoute(item)}
                                >
                                  <TiDeleteOutline />
                                </button>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
};

export default WineRoutesList;
