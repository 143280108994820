import React, { useState, useEffect, useRef } from "react";
import Masonry from "react-masonry-css";
import Wine from "./Wine";
import Spinner from "./Spinner";

const breakpointObj = {
  default: 4,
  3000: 6,
  2000: 5,
  1200: 3,
  1000: 2,
  500: 1,
};

const MasonryLayout = ({ wines }) => {
  const [hasMore, setHasMore] = useState(wines?.length > 10); // state of whether more items to load
  const [loadMore, setLoadMore] = useState(false); // state to trigger add more
  const [pages, setPages] = useState([...wines.slice(0, 10)]);
  const loadRef = useRef();

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };
  // initialize intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, []);

  // const handleLoadMore = () => {
  //   console.log("loading...");
  //   setLoadMore(true);
  // };

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = pages.length;
      const isMore = currentLength < wines.length;
      const nextResults = isMore
        ? wines.slice(currentLength, currentLength + 10)
        : [];
      setPages([...pages, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, pages, wines]);

  useEffect(() => {
    const isMore = pages?.length < wines?.length;
    setHasMore(isMore);
  }, [pages, wines]);

  return (
    <>
      <Masonry
        className="flex animate-slide-fwd"
        breakpointCols={breakpointObj}
      >
        {pages?.map((wine) => (
          <Wine key={wine._id} wine={wine} className="w-max" />
        ))}
      </Masonry>
      <div ref={loadRef}>
        {hasMore ? <Spinner message="Loading...!" /> : <p>No more results</p>}
      </div>
    </>
  );
};

export default MasonryLayout;
