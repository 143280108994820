import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const isNotActiveStyle =
  "flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize";
const isActiveStyle =
  "flex items-center px-5 gap-3 font-extrabold virder-r-2 border-black transition-all duration-200 ease-in-out capitalize";

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <NavLink
        to={item.path}
        onClick={item.subNav && showSubnav}
        className={({ isActive }) =>
          isActive ? isActiveStyle : isNotActiveStyle
        }
        key={item.title}
      >
        <div>{item.title}</div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </NavLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <NavLink
              to={item.path}
              key={index}
              className="text-gray-500 hover:text-blue-900 pl-8"
            >
              {item.title}
            </NavLink>
          );
        })}
    </>
  );
};

export default SubMenu;
