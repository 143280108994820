import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { client, urlFor } from "../client";
import MasonryLayoutSearch from "./MasonryLayoutSearch";
import { wineDetailMoreWineQuery, wineDetailQuery } from "../utils/data";
import Spinner from "./Spinner";
import Map from "./Map";
import { Rate } from "antd";
import { Helmet } from "react-helmet";
import { PortableText } from "@portabletext/react";

import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import * as IoIcons from "react-icons/io";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";

const WineDetail = ({ user }) => {
  const [wines, setWines] = useState(null);
  const [wineDetail, setWineDetail] = useState(null);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(2);
  const [addingComment, setAddingComment] = useState(false);
  const { wineId } = useParams();

  const ptComps = {
    block: {
      h1: ({ children }) => (
        <h1 className="Text-4xl pt-2 font-extrabold">{children}</h1>
      ),

      h2: ({ children }) => (
        <h2 className="text-indigo-900 text-3xl pt-2 font-extrabold">
          {children}
        </h2>
      ),
      h3: ({ children }) => (
        <h3 className="text-indigo-900 text-3xl pt-2 font-extrabold">
          {children}
        </h3>
      ),
      normal: ({ children }) => <p className="p-2">{children}</p>,
    },
    list: {
      bullet: ({ children }) => (
        <div className="mx-auto pl-12 pr-2">
          <ul className="mt-xl list-disc">{children}</ul>
        </div>
      ),
    },
  };

  const addComment = () => {
    if (comment) {
      setAddingComment(true);
      client
        .patch(wineId)
        .setIfMissing({ comments: [] })
        .insert("after", "comments[-1]", [
          {
            comment,
            rating,
            _key: uuidv4(),
            userId: user._id,
            postedBy: { _type: "postedBy", _ref: user._id },
          },
        ])
        .commit()
        .then(() => {
          fetchWineDetails();
          setComment("");
          setAddingComment(false);
        });
    }
  };

  const fetchWineDetails = useCallback(() => {
    let query = wineDetailQuery(wineId);

    if (query) {
      client.fetch(query).then((data) => {
        setWineDetail(data[0]);
        if (data[0]) {
          query = wineDetailMoreWineQuery(data[0]);
          client.fetch(query).then((res) => setWines(res));
        }
      });
    }
  }, [wineId]);

  useEffect(() => {
    fetchWineDetails();
  }, [wineId, fetchWineDetails]);

  if (!wineDetail) return <Spinner message="Loading winery ..." />;

  const isFood = wineDetail.services.food;
  const isTours = wineDetail.services.tours;
  const isTastings = wineDetail.services.tastings;
  const isAccommodation = wineDetail.services.accommodation;
  const isWeddings = wineDetail.services.weddings;
  const isOrganic = wineDetail.services.organic;
  const isChildFriendly = wineDetail.services.children;

  const streetAddress =
    wineDetail.address.street +
    ", " +
    wineDetail.address.state +
    ", " +
    wineDetail.address.country;

  const location = {
    address: streetAddress,
    lat: wineDetail.address.latitude,
    lng: wineDetail.address.longitude,
  };

  const TITLE = wineDetail.title;

  return (
    <>
      <Helmet>
        <title>{TITLE} | Details | Winery Seeker</title>
        <meta name="title" content={(" | ", TITLE)} />
        <meta name="description" content={wineDetail.about} />
      </Helmet>
      <div
        className="flex xl-flex-row flex-col m-auto bg-white"
        style={{ maxWidth: "1500px", borderRadius: "32px" }}
      >
        <div className="flex justify-center items-center md:items-start flex-initial">
          <img
            src={
              wineDetail?.image && urlFor(wineDetail.image).auto("format").url()
            }
            className="rounded-t-3xl rounded-b-lg"
            alt={TITLE}
          />
        </div>

        <div className="w-full p-5 flex-1 xl:min-w-620">
          <div className="flex items-center justify-between">
            <a
              href={wineDetail.contact.website}
              target="_blank"
              rel="noreferrer"
            >
              {wineDetail.contact.website}
            </a>
          </div>
          <div className="mb-10">
            <h1 className="text-4xl font-bold break-words mt-3">
              {wineDetail.title}
            </h1>
            <h3 className="font-semibold">Tasting fee: ${wineDetail.tastingFee}</h3>
            <p className="mt-3 pl-2">{wineDetail.about}</p>
            <div className="mt-3">
              <PortableText value={wineDetail.content} components={ptComps} />
            </div>
          </div>

          <div className="flex flex-wrap gap-4 h-200 mx-auto flex-col sm:flex-row">
            <div className="border border-black flex-auto rounded-xl">
              <h1 className="text-blue-900 text-2xl font-bold text-center">
                Contact
              </h1>
              <p className="pl-4">{wineDetail.address.street}</p>
              <p className="pl-4">
                {wineDetail.address.city}, {wineDetail.address.state},{" "}
                {wineDetail.address.country}
              </p>
              <p className="pl-4">{wineDetail.contact.phone}</p>
              <br />
              <Link
                to={wineDetail.contact.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="text-sm bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full ml-4 mb-4"
                >
                  Website
                </button>
              </Link>

              <Link
                to={wineDetail.contact.reservationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="text-sm bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded-full float-right mr-4 mb-4"
                >
                  Reservations
                </button>
              </Link>
            </div>
            <div className="warm-gray-50 flex-auto border border-black rounded-xl">
              <h1 className="text-blue-900 text-2xl font-bold text-center">
                Hours
              </h1>
              <div className="grid grid-cols-1 justify-items-center">
                <table className="table-auto border-separate border-spacing-[.5] text-left">
                  <tbody>
                    <tr>
                      <th>Monday:</th>
                      <td>{wineDetail.hours.monday}</td>
                    </tr>
                    <tr>
                      <th>Tuesday:</th>
                      <td>{wineDetail.hours.tuesday}</td>
                    </tr>
                    <tr>
                      <th>Wednesday:</th>
                      <td>{wineDetail.hours.wednesday}</td>
                    </tr>
                    <tr>
                      <th>Thursday:</th>
                      <td>{wineDetail.hours.thursday}</td>
                    </tr>
                    <tr>
                      <th>Friday:</th>
                      <td>{wineDetail.hours.friday}</td>
                    </tr>
                    <tr>
                      <th>Saturday:</th>
                      <td>{wineDetail.hours.saturday}</td>
                    </tr>
                    <tr>
                      <th>Sunday:</th>
                      <td>{wineDetail.hours.sunday}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-pink-50 flex-auto border border-black rounded-xl">
              <h1 className="text-blue-900 text-2xl font-bold text-center">
                Services
              </h1>
              {isTours ? (
                <div className="flex flex-row pb-1">
                  <div className="justify-center items-center pl-2">
                    <GiIcons.GiGrapes />
                  </div>
                  <div className="pl-2">Tours</div>
                </div>
              ) : (
                <></>
              )}

              {isTastings ? (
                <div className="flex flex-row pb-1">
                  <div className="justify-center items-center pl-2">
                    <FaIcons.FaWineBottle />
                  </div>
                  <div className="pl-2">Tastings</div>
                </div>
              ) : (
                <></>
              )}
              {isAccommodation ? (
                <div className="flex flex-row pb-1">
                  <div className="justify-center items-center pl-2">
                    <FaIcons.FaBed />
                  </div>
                  <div className="pl-2">Accommodation</div>
                </div>
              ) : (
                <></>
              )}
              {isFood ? (
                <div className="flex flex-row pb-1">
                  <div className="justify-center items-center pl-2">
                    <BiIcons.BiFoodMenu />
                  </div>
                  <div className="pl-2">Food</div>
                </div>
              ) : (
                <></>
              )}
              {isWeddings ? (
                <div className="flex flex-row pb-1">
                  <div className="justify-center items-center pl-2">
                    <FaIcons.FaHeart />
                  </div>
                  <div className="pl-2">Weddings</div>
                </div>
              ) : (
                <></>
              )}
              {isOrganic ? (
                <div className="flex flex-row pb-1">
                  <div className="justify-center items-center pl-2">
                    <IoIcons.IoMdLeaf />
                  </div>
                  <div className="pl-2">Organic</div>
                </div>
              ) : (
                <></>
              )}
              {isChildFriendly ? (
                <div className="flex flex-row">
                  <div className="justify-center items-center pl-2">
                    <MdIcons.MdChildFriendly />
                  </div>
                  <div className="pl-2">Child Friendly</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="mt-4 bm-4 w-full rounded overflow-hidden shadow-lg bg-slate-50 pl-2 pr-2">
            <h1 className="font-bold text-2xl text-blue-900 text-center pb-4">
              Varietals
            </h1>
            <div className="flex flex-wrap max-w-3xl">
              {wineDetail.varietals_red?.map((varietal, index) => (
                <button
                  className="py-1 px-2 shadow-md no-underline text-xs rounded-full bg-rose-600 text-white font-sans font-semibold border-blue btn-primary hover:text-white hover:bg-rose-500 focus:outline-none active:shadow-none mr-2 mb-2"
                  key={index}
                >
                  {varietal}
                </button>
              ))}
              {wineDetail.varietals_white?.map((varietal, index) => (
                <button
                  className="py-1 px-2 shadow-md no-underline text-xs rounded-full bg-amber-400 text-white font-sans font-semibold border-blue btn-primary hover:text-white hover:bg-amber-200 focus:outline-none active:shadow-none mr-2 mb-2"
                  key={index}
                >
                  {varietal}
                </button>
              ))}
              {wineDetail.varietals_rose?.map((varietal, index) => (
                <button
                  className="py-1 px-2 shadow-md no-underline text-xs rounded-full bg-red-400 text-white font-sans font-semibold border-blue btn-primary hover:text-white hover:bg-red-300 focus:outline-none active:shadow-none mr-2 mb-2"
                  key={index}
                >
                  {varietal}
                </button>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <h2 className="font-bold text-2xl text-blue-900 text-center">
              Map
            </h2>
            <Map location={location} zoomLevel={17} />
          </div>

          <h2 className="mt-5 text-2xl">Reviews</h2>
          <div className="max-h-370 overflow-y-auto">
            {wineDetail?.comments?.map((comment, i) => (
              <div
                className="flex gap-2 mt-5 items-center bg-white rounded-lg"
                key={i}
              >
                <img
                  src={comment.postedBy.image}
                  alt="user-profile"
                  className="w-10 h-10 rounded-full cursor-pointer"
                />
                <div className="flex flex-col">
                  <div className="font-bold">{comment.postedBy.userName}</div>
                  <div className="font-bold text-xs">
                    <Rate
                      defaultValue={comment.rating}
                      allowHalf
                      allowClear={false}
                      disabled
                    />
                  </div>
                  <div>{comment.comment}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap mt-6 gap-3">
            {user ? (
              <>
                <Link to={`user-profile/${wineDetail.postedBy?._id}`}>
                  <img
                    className="w-10 h-10 rounded-full cursor-pointer"
                    src={wineDetail.postedBy?.image}
                    alt="user-profile"
                  />
                </Link>

                <Rate
                  defaultValue={3}
                  allowHalf
                  allowClear={true}
                  onChange={(value) => {
                    setRating(value);
                  }}
                  value={rating}
                />
                <input
                  type="text"
                  placeholder="add a review..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="flex-1 border-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300"
                />
                <button
                  type="button"
                  className="bg-red-500 text-white rounded-full px-6 py-2 font-semibold text-base outline-none"
                  onClick={addComment}
                >
                  {addingComment ? "Posting the review" : "Post"}
                </button>
              </>
            ) : (
              <input
                type="text"
                placeholder="login to leave a review..."
                disabled="disabled"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="flex-1 border-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300"
              />
            )}
          </div>
        </div>
      </div>
      {wines?.length > 0 ? (
        <>
          <h2 className="text-center font-bold text-2x mt-8 mb-4">
            More like this
          </h2>
          <MasonryLayoutSearch wines={wines} />
        </>
      ) : (
        <Spinner message="loading more wineries" />
      )}
    </>
  );
};

export default WineDetail;
