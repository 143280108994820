import React from "react";
import Masonry from "react-masonry-css";
import Wine from "./Wine";

const breakpointObj = {
  default: 4,
  3000: 6,
  2000: 5,
  1200: 3,
  1000: 2,
  500: 1,
};

const MasonryLayout = ({ wines }) => {
  return (
    <Masonry className="flex animate-slide-fwd" breakpointCols={breakpointObj}>
      {wines?.map((wine) => (
        <Wine key={wine._id} wine={wine} className="w-max" />
      ))}
    </Masonry>
  );
};

export default MasonryLayout;
