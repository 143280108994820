import React, { useState, useEffect } from "react";
import { articlesQuery } from "../utils/data";
import { client, urlFor } from "../client";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";

const ArticleGrid = () => {
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    setLoading(true);
    client.fetch(articlesQuery).then((data) => {
      setArticles(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <Spinner message="Searching for articles...!" />;

  return (
    <div>
      <h1 className="mt-10 text-7xl font-extrabold">
        <span className="bg-gradient-to-r from-orange-400 via-red-400 to-purple-600 bg-clip-text text-transparent">
          Winery Seeker
        </span>{" "}
        Wine Knowledge Base
      </h1>
      <p className="mt-3 text-xl text-gray-600">
        Love, laugh and learn about wine with our weekly articles on all things
        related to winery and wine production.
      </p>
      <h2 className="mt-10 font-bold text-gray-700 text-3xl">Articles</h2>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {articles?.map((article) => (
          <Link to={`${article?.slug}`} key={article._id} className="text-lg">
            {article.image && (
              <img
                src={
                  article?.image && urlFor(article.image).auto("format").url()
                }
                className="rounded-t-3xl rounded-b-lg"
                alt={article?.title}
                width="400"
                height="400"
              />
            )}
            <div className="mt-2 font-extrabold bg-gradient-to-r from-orange-400 via-red-400 to-purple-600 bg-clip-text text-transparent">
              {article.title}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ArticleGrid;
