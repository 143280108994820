import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Navbar, Feed, WineDetail, Search } from "../components";
import WinesMap from "./WinesMap";

// import { Navbar, Feed, WineDetail, CreatePin, Search } from "../components";

const Wines = ({ user }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleMapButton, setToggleMapButton] = useState(false);

  return (
    <div className="px-2 md:px-5">
      <div className="bg-gray-50">
        <Navbar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          user={user}
          toggleMapButton={toggleMapButton}
          setToggleMapButton={setToggleMapButton}
        />
      </div>
      <div className="h-full ">
        <Routes>
          <Route
            path="/"
            element={<Feed toggleMapButton={toggleMapButton} />}
          />
          <Route
            path="/category/:categoryId"
            element={<Feed toggleMapButton={toggleMapButton} />}
          />
          <Route
            path="/wine-detail/:wineId"
            element={<WineDetail user={user} />}
          />
          <Route
            path="/search"
            element={
              <Search
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                toggleMapButton={toggleMapButton}
              />
            }
          />
          <Route path="WinesMap" element={<WinesMap />} />
        </Routes>
      </div>
    </div>
  );
};

export default Wines;
