import { client } from "./client";
import { slugsQuery } from "./utils/data";

import React, { useState, useEffect } from "react";

const SanityUrls = () => {
  const [slugs, setSlugs] = useState(null);

  useEffect(() => {
    client.fetch(slugsQuery).then((data) => {
      setSlugs(data);
    });
  }, []);

  //   const urls = slugs.map((item) => {
  //     return `<loc>${item.slug}</loc>`;
  //   });

  return (
    <>
      <h1> Site Map</h1>
      {slugs?.map((item, index) => (
        <p key="index">{item.slug}</p>
      ))}
    </>
  );
};

export default SanityUrls;
