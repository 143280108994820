import React from "react";
// import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";
import { StateContext } from "./context/StateContext";

const tagManagerArgs = {
  gtmId: "GTM-T2HXLT5",
};
TagManager.initialize(tagManagerArgs);

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <Router>
//     <App />
//   </Router>
// );

const route = (
  <Router>
    <App />
  </Router>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  // eslint-disable-next-line
  const root = ReactDOMClient.hydrateRoot(
    document.getElementById("root", route)
  );
} else {
  const root = ReactDOMClient.createRoot(document.getElementById("root"));
  root.render(
    <StateContext>
      <Router>
        <App />
      </Router>
    </StateContext>
  );
}
