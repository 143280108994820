import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Argentina",
    path: "/category/argentina",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    description: "Argentina is home of some of the finest wines the the world.",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
    subNav: [
      {
        title: "Mendoza",
        path: "/category/mendoza",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "San Juan",
        path: "/category/san%20juan",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Canada",
    path: "category/canada",
    icon: <FaIcons.FaCanadianMapleLeaf />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    description: "Canada is home of some of the finest icewines the the world.",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
    subNav: [
      {
        title: "Niagara Falls",
        path: "/category/niagara",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Prince Edward County",
        path: "/category/prince%20edward%20county",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Okanagan Valley",
        path: "/category/okanagan%20valley",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Chile",
    path: "/category/Chile",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    description: "Chile is home of some of the finest malbecs the the world.",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
    subNav: [
      {
        title: "Maipo Valley",
        path: "/category/maipo",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Aconcagua",
        path: "/category/aconcagua",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "USA",
    path: "/category/usa",
    icon: <FaIcons.FaFlagUsa />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    description: "USA is home of some of the finest syrahs the the world.",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
    subNav: [
      {
        title: "Columbia Valley (WA)",
        path: "/category/columbia%20valley",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Lodi (CA)",
        path: "/category/lodi",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Napa Valley (CA)",
        path: "/category/napa%20valley",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Sonoma County (CA)",
        path: "/category/sonoma%20county",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "San Luis Obispo (CA)",
        path: "/category/San%20Luis%20Obispo",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Willamette Valley (OR)",
        path: "/category/willamette%20valley",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "France",
    path: "/category/france",
    icon: <FaIcons.FaFlag />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    description:
      "France is home of some of the most expensive wines the the world.",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
    subNav: [
      {
        title: "Bourdeaux",
        path: "/category/bourdeaux",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Burgundy",
        path: "/category/burgundy",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Pomerol",
        path: "/category/pomerol",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Italy",
    path: "/category/italy",
    icon: <FaIcons.FaFlag />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    description:
      "Italy is synonymous with wine, boasting a heritage that stretches back thousands of years. From the rolling hills of Tuscany to the volcanic soils of Sicily, the country's diverse landscapes give birth to some of the world’s most revered and distinctive wines. Italian wines offer a sublime mix of tradition, terroir, and taste, captivating wine enthusiasts across the globe.\nItaly is home to 20 wine-growing regions, each producing wines with distinct flavors and personalities. Whether you’re exploring the elegance of Piedmont, the rustic charm of Tuscany, or the coastal breezes of Campania, Italian wines offer a diversity of experiences. The country’s geographical diversity ensures that every bottle carries a unique story rooted in the land it comes from.",
    image:
      "https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg",
    subNav: [
      {
        title: "Abruzzo",
        path: "/category/abruzzo",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Piedmont",
        path: "/category/piedmont",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Puglia",
        path: "/category/puglia",
        icon: <IoIcons.IoIosPaper />,
      },
      ,
      {
        title: "Tuscany",
        path: "/category/tuscany",
        icon: <IoIcons.IoIosPaper />,
      },
      ,
      {
        title: "Veneto",
        path: "/category/veneto",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Greece",
    path: "/category/greece",
    icon: <FaIcons.FaFlagCheckered />,
    description: "Greece is home of some wines.",
  },
];
