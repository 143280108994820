import React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";

const Navbar = ({
  searchTerm,
  setSearchTerm,
  user,
  toggleMapButton,
  setToggleMapButton,
}) => {
  const navigate = useNavigate();
  const search_params = { q: `${searchTerm}` };

  return (
    <>
      <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7">
        <div className="flex justify-start items=center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
          <IoMdSearch fontSize={21} className="ml-1" />
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            value={searchTerm}
            onFocus={() =>
              navigate({
                pathname: "/search",
                search: `?${createSearchParams(search_params)}`,
              })
            }
            onBlur={() => setSearchTerm("")}
            // onFocus={() => navigate("/search")}
            className="p-2 w-full bg-white outline-none"
          />
        </div>
        <div className="flex">
          <label className="inline-flex relative items-center mb-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={toggleMapButton}
              id="map-view"
              value={toggleMapButton}
              onChange={(e) => {}}
              onClick={() => {
                window.dataLayer.push({ event: "toggle-map" });
              }}
            />
            <div
              onClick={() => {
                setToggleMapButton(!toggleMapButton);
              }}
              className="w-9 h-5 bg-gray-200 rounded-full peer peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-600"
            ></div>
            <span className="ml-2 text-sm font-medium text-gray-900">Map</span>
          </label>
        </div>
      </div>
    </>
  );
};

export default Navbar;
