import React from "react";

const Terms = () => {
  return (
    <>
      <iframe
        className="w-full h-full"
        title="terms of service"
        srcDoc={`
      <div id="__enzuzo-root"></div>
      <script
        id="__enzuzo-root-script"
        src="https://app.enzuzo.com/__enzuzo-privacy-app.js?mode=tos&apiHost=https://app.enzuzo.com&qt=1689954818941&referral=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzU4MiwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1XNEJFdGRyOCIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2ODk5NTQ4MTl9.V-RAXi4YkVcOLoGUfJdxyFhnKWhweD4gX3-DkDFpT4g"
      ></script>`}
      />
    </>
  );
};

export default Terms;
